
export default {
	name: 'crcWorkList',
	data() {
		return {
			list: '',
			total: 0,
			pageSize: 0,
			currentPage: 1,
			crcWorkDateStart: new Date(), //CRC工作量统计开始时间
			crcWorkDateEnd: new Date(), //CRC工作量统计结束时间
		}
	},

	created() {
		this.token = this.getCookie('token')
		this.crcWorkDateStart = this.getCrcWorkDateEnd(this.crcWorkDateStart)
		this.getcrcWorkListList()
	},
	methods: {
		//获取系统前一个月的时间
		getCrcWorkDateEnd(date) {
			date.setMonth(date.getMonth() - 2);
			var y = date.getFullYear();
			var m = date.getMonth() + 1;
			var d = date.getDate();
			var h = date.getHours();
			var minute = date.getMinutes();
			minute = minute < 10 ? ('0' + minute) : minute;
			var second = date.getSeconds();
			second = minute < 10 ? ('0' + second) : second;
			//				date = y + '-' + m-1 + '-' + d;
			date = new Date(y, m, d, h, minute, second)
			return date
		},
		getcrcWorkListList() {
			//				console.log(this.search)
			var _this = this;
			_this.$http.post('/API/PLAT/findAllCrcJobNum', {
				startTime: _this.crcWorkDateStart,
				endTime: _this.crcWorkDateEnd
			}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.list = response.data.data.array
				}
			})
		},
		changeEchar1(start, end) {
			var _this = this;
			_this.$http.post('/API/PLAT/findAllCrcJobNum', {
				startTime: start,
				endTime: end
			}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.list = response.data.data.array
				}
			})
		},
		//点击分页
		currentPageChanged(currentPage) {
			this.currentPage = currentPage
			this.getOfficeList()
		},
		//跳转CRC详情
		toPar(id) {
			this.$router.push({ name: 'crcParticulars', params: { id: id } });
		},
		//面包屑
		toCrcMena() {
			this.$router.push('/statistics')
		},
		//导出
		export2Excel() {
			var _this = this;
			if (_this.list) {
				_this.$http.post('/API/PLAT/inPageAExcel', {
					name: _this.search,
					smoId: _this.searchid
				}, {
					headers: { "token": _this.token }
				}).then(function (response) {
					require.ensure([], () => {
						const {
							export_json_to_excel
						} = require('../../vendor/Export2Excel');
						const tHeader = ['CRC', 'SMO', '年龄', '学历', '专业', '从业年限', '项目数量'];
						const filterVal = ['crcName', 'enterpriseName', 'years', 'education', 'major', 'age', 'psize'];
						const data = _this.formatJson(filterVal, response.data.data.objArray);
						export_json_to_excel(tHeader, data, '科室人员详情列表');
					})
				});
			} else {
				this.$message.error('操作异常');
			}
		},
		formatJson(filterVal, jsonData) {
			return jsonData.map(v => filterVal.map(j => v[j]))
		}
	}
}
